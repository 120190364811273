import * as React from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles/global.css';

// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import '@mantine/core/styles.css';

import { MantineProvider } from '@mantine/core';
// import { theme } from './src/ui/theme'; // to override styles create this file

export const wrapPageElement = ({ element }) => {
  return <MantineProvider>{element}</MantineProvider>; // theme={theme}
};
